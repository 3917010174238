import { actionFirebaseRequest } from '~/store/firebase/index.js';
import { isUndefined, get, omitBy } from 'lodash';
import {
  activityHubEventNames,
  candidatesTrackingEventNames,
  feedbackWidgetEventNames,
  findAddressTrackEventName,
  findCityTrackEventName,
  invitationTrackingEventNames,
  inviteLinkEventNames,
  leadGeneratorWidgetEventNames,
  liveChatEventNames,
  manualAddressEventNames,
  navigationEventNames,
  rolePickerEventNames,
  searchTrackingEventNames,
  sessionEventName,
  trackingFlowNames,
  trackingProcessNames,
  trackingProperties,
} from '~/utils/trackerConstants.js';
import * as Sentry from '@sentry/nuxt';

export const state = () => ({});

export const mutations = () => ({});

const removeUndefinedParametersFromObjectAndReturnNew = (o = {}) =>
  omitBy({ ...o }, isUndefined);
const requestPostMergeCustomerIoIdentities = 'postMergeCustomerIoIdentities';

export const actions = {
  firebaseRequest: actionFirebaseRequest(),
  updateUserPropertiesBasedOnStore: function (
    { commit, dispatch, getters, rootGetters },
    overrideProperties = {},
  ) {
    const nuxtApp = useNuxtApp();

    const rawUserProperties = {
      firstName: get(rootGetters['users/profile'], 'firstName', undefined),
      lastName: get(rootGetters['users/profile'], 'lastName', undefined),
      email: get(rootGetters['users/profile'], 'email', undefined),
      phoneNumberFull: get(rootGetters['users/profile'], 'phoneNumberFull', undefined),
      firebaseUuid: get(rootGetters['users/firebaseUser'], 'uid', undefined),
      firebaseIsAnonymous: get(
        rootGetters['users/firebaseUser'],
        'isAnonymous',
        undefined,
      ),
      firebaseIsEmailVerified: get(
        rootGetters['users/firebaseUser'],
        'emailVerified',
        undefined,
      ),
      isDev: rootGetters.isENVIsDevelopment,
      tenantCheck: get(
        rootGetters['tenants/tenantProfileFromFirebase'],
        'scoringValue',
        undefined,
      ),
      country: rootGetters.getCountry,
      locale: rootGetters.getLocale,
      market: rootGetters.getCountry,
      ...overrideProperties,
    };

    const userProperties =
      removeUndefinedParametersFromObjectAndReturnNew(rawUserProperties);

    const profileId = rootGetters['users/profileId'];

    const params = {
      identify: !!(profileId || userProperties.email || userProperties.phoneNumberFull),
      email: userProperties.email,
      phoneNumberFull: userProperties.phoneNumberFull,
      profileId: profileId,
      services: ['POSTHOG', 'GTAG', 'CIO'],
      internalUserProperties: userProperties,
    };
    return nuxtApp.$setUserProperties(params);
  },
  buildSuperPropertiesBasedOnStore: function (
    { commit, dispatch, getters, rootGetters },
    overrideProperties = {},
  ) {
    const rawSuperProperties = {
      userHasSession: rootGetters['users/hasSessionUser'],
      webCountry: rootGetters.getCountry,
      market: rootGetters.getCountry,
      locale: rootGetters.getLocale,
      webVersion: '2.0',
      currentRole: rootGetters['users/userRole'],
      ...overrideProperties,
    };

    return removeUndefinedParametersFromObjectAndReturnNew(rawSuperProperties);
  },
  reportErrorToSentry: function (context, err) {
    if (err) {
      Sentry.captureException(err);
    }
  },
  trackSearchListingsEvent: function (
    { getters, rootGetters },
    { eventName, props = {}, options = {} },
  ) {
    const defaultOptions = rootGetters.isENVIsDevelopment
      ? { services: ['CIO', 'POSTHOG', 'GTAG'] }
      : { services: ['POSTHOG', 'GTAG', 'FB', 'CIO'] };
    const finalOptions = { ...defaultOptions, ...options };

    const finalProps = { ...props };

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackSearchListingsEvent '${eventName}' props: `, finalProps);
      console.log(`trackSearchListingsEvent '${eventName}' options: `, finalOptions);
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackSortAndFilterEvent: function (
    { getters, rootGetters },
    { eventName, processName, sorting, searchString },
  ) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = { processName: processName };
    if (sorting) finalProps.sorting = sorting;
    if (searchString) finalProps.sorting = searchString;

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackSearchListingsEvent '${eventName}' props: `, finalProps);
      console.log(`trackSearchListingsEvent '${eventName}' options: `, finalOptions);
    }

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackSuggestionsFetchEvent: function (
    { getters, rootGetters },
    { props = {}, options = {} },
  ) {
    const eventName = searchTrackingEventNames.SUGGESTIONS_FETCH;

    const defaultOptions = rootGetters.isENVIsDevelopment
      ? { services: ['CIO', 'POSTHOG', 'GTAG'] }
      : { services: ['POSTHOG', 'GTAG', 'FB', 'CIO'] };
    const finalOptions = { ...defaultOptions, ...options };

    const finalProps = { ...props };

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackSuggestionsFetchEvent '${eventName}' props: `, finalProps);
      console.log(`trackSuggestionsFetchEvent '${eventName}' options: `, finalOptions);
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackSuggestionsClickEvent: function (
    { getters, rootGetters },
    { props = {}, options = {} },
  ) {
    const eventName = searchTrackingEventNames.SUGGESTIONS_CLICK;

    const defaultOptions = rootGetters.isENVIsDevelopment
      ? { services: ['CIO', 'POSTHOG', 'GTAG'] }
      : { services: ['POSTHOG', 'GTAG', 'FB', 'CIO'] };
    const finalOptions = { ...defaultOptions, ...options };

    const finalProps = { ...props };

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackSuggestionsClickEvent '${eventName}' props: `, finalProps);
      console.log(`trackSuggestionsClickEvent '${eventName}' options: `, finalOptions);
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackInvitationStartLoginEvent: function (
    { getters, rootGetters },
    { advertisementId, agreementId, code, authProvider },
  ) {
    const eventName = invitationTrackingEventNames.START_LOGIN;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      advertisementId: advertisementId,
      agreementId: agreementId,
      code: code,
      authProvider: authProvider,
    };
    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackInvitationStartLoginEvent '${eventName}' props: `, finalProps);
      console.log(
        `trackInvitationStartLoginEvent '${eventName}' options: `,
        finalOptions,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackInvitationFillProfileEvent: function (
    { getters, rootGetters },
    { advertisementId, agreementId, code },
  ) {
    const eventName = invitationTrackingEventNames.FILL_PROFILE;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      advertisementId: advertisementId,
      agreementId: agreementId,
      code: code,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackInvitationFillProfileEvent '${eventName}' props: `, finalProps);
      console.log(
        `trackInvitationFillProfileEvent '${eventName}' options: `,
        finalOptions,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackInvitationSubmitBackgroundCheckEvent: function (
    { getters, rootGetters },
    { advertisementId, agreementId, code },
  ) {
    const eventName = invitationTrackingEventNames.SUBMIT_BACKGROUND_CHECK;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      advertisementId: advertisementId,
      agreementId: agreementId,
      code: code,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(
        `trackInvitationSubmitBackgroundCheckEvent '${eventName}' props: `,
        finalProps,
      );
      console.log(
        `trackInvitationSubmitBackgroundCheckEvent '${eventName}' options: `,
        finalOptions,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackInvitationUseGallery: function (
    { getters, rootGetters },
    { advertisementId, agreementId, code },
  ) {
    const eventName = invitationTrackingEventNames.USE_GALLERY;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      advertisementId: advertisementId,
      agreementId: agreementId,
      code: code,
    };
    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackInvitationUseGallery '${eventName}' props: `, finalProps);
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackInvitationClickWhyNoDeposit: function (
    { getters, rootGetters },
    { advertisementId, agreementId, code },
  ) {
    const eventName = invitationTrackingEventNames.CLICK_WHY_NO_DEPOSIT;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      advertisementId: advertisementId,
      agreementId: agreementId,
      code: code,
    };
    if (rootGetters.isENVIsDevelopment) {
      console.log(
        `trackInvitationClickWhyNoDeposit '${eventName}' props: `,
        finalProps,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackInvitationClickRendinFeeInfoModal: function (
    { getters, rootGetters },
    { advertisementId, agreementId, code },
  ) {
    const eventName = invitationTrackingEventNames.CLICK_RENDIN_FEE_INFO_MODAL;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      advertisementId: advertisementId,
      agreementId: agreementId,
      code: code,
    };
    if (rootGetters.isENVIsDevelopment) {
      console.log(
        `trackInvitationClickRendinFeeInfoModal '${eventName}' props: `,
        finalProps,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackInvitationApplyAsCandidateEvent: function (
    { getters, rootGetters },
    { advertisementId, agreementId, code },
  ) {
    const eventName = invitationTrackingEventNames.APPLY;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      kv24: code.length > 4,
      advertisementId: advertisementId,
      agreementId: agreementId,
      code: code,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(
        `trackInvitationApplyAsCandidateEvent '${eventName}' props: `,
        finalProps,
      );
      console.log(
        `trackInvitationApplyAsCandidateEvent '${eventName}' options: `,
        finalOptions,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackInvitationCancelCandidacyEvent: function (
    { getters, rootGetters },
    { advertisementId, agreementId, code },
  ) {
    const eventName = invitationTrackingEventNames.CANCEL;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      advertisementId: advertisementId,
      agreementId: agreementId,
      code: code,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(
        `trackInvitationCancelCandidacyEvent '${eventName}' props: `,
        finalProps,
      );
      console.log(
        `trackInvitationCancelCandidacyEvent '${eventName}' options: `,
        finalOptions,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  /**
   *
   * @param {string} eventName
   * @param {object} props
   * @param {object} options
   */
  trackDigibrokerEvent: function (
    { getters, rootGetters },
    { eventName, props = {}, options = {} },
  ) {
    const defaultProps = {
      [getters.getAnalyticEventProperties.EVENT_FLOW_NAME]:
        getters.getAnalyticEventFlowNames.DIGIBROKER,
    };
    const defaultOptions = rootGetters.isENVIsDevelopment
      ? { services: ['CIO', 'POSTHOG', 'GTAG'] }
      : { services: ['POSTHOG', 'GTAG', 'FB', 'CIO'] };
    const finalProps = { ...defaultProps, ...props };
    const finalOptions = { ...defaultOptions, ...options };
    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackDigibrokerEvent '${eventName}' props: `, finalProps);
      console.log(`trackDigibrokerEvent '${eventName}' options: `, finalOptions);
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  /**
   * @param {string} eventName
   * @param {string} processName
   * @param {object} props
   * @param {object} agreement
   * @param {object} options
   */

  trackAgreementFlowEvent: function (
    { rootGetters },
    { eventName, processName, props = {}, agreement = {}, options = {} },
  ) {
    const defaultPropsFromAgreement = agreement
      ? {
          [trackingProperties.AGREEMENT_ID]: agreement ? agreement.id : null,
          [trackingProperties.AGREEMENT_WORKFLOW]: agreement
            ? agreement.workflow
            : null,
          [trackingProperties.AGREEMENT_TYPE]: agreement
            ? agreement.agreementTypeFromAgreement
            : null,
          [trackingProperties.AGREEMENT_STATUS]: agreement ? agreement.status : null,
          [trackingProperties.AGREEMENT_LANDLORD_ID]: agreement
            ? agreement.landlordProfileId
            : null,
          [trackingProperties.AGREEMENT_CREATOR_ID]: agreement
            ? agreement.creatorProfileId
            : null,
          [trackingProperties.AGREEMENT_TENANT_ID]: agreement
            ? agreement.tenantProfileId
            : null,
          [trackingProperties.EVENT_FLOW_NAME]:
            processName || trackingProcessNames.AGREEMENT,
        }
      : {};

    const defaultProps = {
      [trackingProperties.EVENT_FLOW_NAME]: trackingFlowNames.AGREEMENT_FLOW,
      ...defaultPropsFromAgreement,
    };
    const defaultOptions = rootGetters.isENVIsDevelopment
      ? { services: ['CIO', 'POSTHOG', 'GTAG'] }
      : { services: ['POSTHOG', 'GTAG', 'FB', 'CIO'] };
    const finalProps = { ...defaultProps, ...props };
    const finalOptions = { ...defaultOptions, ...options };
    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackAgreementFlowEvent '${eventName}' props: `, finalProps);
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackDigibrokerUploadImageEvent: function ({ dispatch, getters }, { props }) {
    return dispatch(
      'tracker/trackDigibrokerEvent',
      { eventName: getters.getAnalyticEventNames.DIGIBROKER_ADD_IMAGE, props: props },
      { root: true },
    );
  },
  trackDigibrokerRemoveImageEvent: function ({ dispatch, getters }) {
    return dispatch(
      'tracker/trackDigibrokerEvent',
      { eventName: getters.getAnalyticEventNames.DIGIBROKER_REMOVE_IMAGE },
      { root: true },
    );
  },
  trackCandidatesViewListEvent: function (
    { getters, rootGetters },
    { processName, agreementId, advertisementId },
  ) {
    const eventName = candidatesTrackingEventNames.VIEW_LIST;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const profileId = rootGetters['users/profileId'];

    const finalProps = {
      processName: processName,
      agreementId: agreementId,
      advertisementId: advertisementId,
      profileId: profileId,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackCandidatesViewListEvent '${eventName}' props: `, finalProps);
      console.log(
        `trackCandidatesViewListEvent '${eventName}' options: `,
        finalOptions,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackCandidatesViewCandidateEvent: function (
    { getters, rootGetters },
    { candidateId, processName, agreementId, advertisementId },
  ) {
    const eventName = candidatesTrackingEventNames.VIEW_CANDIDATE;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const profileId = rootGetters['users/profileId'];

    const finalProps = {
      candidateId: candidateId,
      processName: processName,
      agreementId: agreementId,
      advertisementId: advertisementId,
      profileId: profileId,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(
        `trackCandidatesViewCandidateEvent '${eventName}' props: `,
        finalProps,
      );
      console.log(
        `trackCandidatesViewCandidateEvent '${eventName}' options: `,
        finalOptions,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackCandidatesAddAsATenantEvent: function (
    { getters, rootGetters },
    { candidateId, processName, agreementId, advertisementId },
  ) {
    const eventName = candidatesTrackingEventNames.ADD_AS_A_TENANT;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const profileId = rootGetters['users/profileId'];

    const finalProps = {
      candidateId: candidateId,
      processName: processName,
      agreementId: agreementId,
      advertisementId: advertisementId,
      profileId: profileId,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(
        `trackCandidatesAddAsATenantEvent '${eventName}' props: `,
        finalProps,
      );
      console.log(
        `trackCandidatesAddAsATenantEvent '${eventName}' options: `,
        finalOptions,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackCandidatesCopyAgreementWithATenantEvent: function (
    { getters, rootGetters },
    { candidateId, processName, agreementId, advertisementId },
  ) {
    const eventName = candidatesTrackingEventNames.COPY_AGREEMENT_WITH_TENANT;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const profileId = rootGetters['users/profileId'];

    const finalProps = {
      candidateId: candidateId,
      processName: processName,
      agreementId: agreementId,
      advertisementId: advertisementId,
      profileId: profileId,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(
        `trackCandidatesAddAsATenantEvent '${eventName}' props: `,
        finalProps,
      );
      console.log(
        `trackCandidatesAddAsATenantEvent '${eventName}' options: `,
        finalOptions,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackCandidatesRemoveCandidateEvent: function (
    { getters, rootGetters },
    { candidateId, processName, agreementId, advertisementId },
  ) {
    const eventName = candidatesTrackingEventNames.REMOVE_CANDIDATE;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const profileId = rootGetters['users/profileId'];

    const finalProps = {
      candidateId: candidateId,
      processName: processName,
      agreementId: agreementId,
      advertisementId: advertisementId,
      profileId: profileId,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(
        `trackCandidatesRemoveCandidateEvent '${eventName}' props: `,
        finalProps,
      );
      console.log(
        `trackCandidatesRemoveCandidateEvent '${eventName}' options: `,
        finalOptions,
      );
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackAddressSearchEvent: function (
    { getters },
    { addressInputValue, results, predictionProvider, source },
  ) {
    const eventName = findAddressTrackEventName.RESULT;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      searchInput: addressInputValue,
      resultsCount: results ? results.length : 0,
      results: results,
      predictionProvider: predictionProvider,
      source: source,
    };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackAddressSelectClickEvent: function (
    { getters },
    { selectedAddress, addressInputValue, predictionProvider, source },
  ) {
    const eventName = findAddressTrackEventName.SELECT;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      selectedAddress: selectedAddress,
      searchInput: addressInputValue,
      predictionProvider: predictionProvider,
      source: source,
    };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackCitySearchEvent: function (
    { getters },
    { addressInputValue, findAddressResult, source },
  ) {
    const eventName = findCityTrackEventName.RESULT;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      searchInput: addressInputValue,
      resultsCount: findAddressResult ? findAddressResult.length : 0,
      results: findAddressResult,
      source: source,
    };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackCitySelectClickEvent: function (
    { getters },
    { selectedAddress, addressInputValue, findAddressResult, source },
  ) {
    const eventName = findCityTrackEventName.SELECT;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      selectedAddress: selectedAddress,
      searchInput: addressInputValue,
      resultsCount: findAddressResult ? findAddressResult.length : 0,
      results: findAddressResult,
      source: source,
    };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackManualAddressClicked: function ({ getters }, { source }) {
    const eventName = manualAddressEventNames.CLICKED;
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = {
      source: source,
    };
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackManualAddressAdded: function (
    { getters },
    {
      addressCity,
      addressStreet,
      addressPostalCode,
      addressHouseNumber,
      addressCountry,
      addressHouseNumberHidden,
      addressFirstLine,
      addressApartmentNumber,
      hasApartmentNumber,
      source,
    },
  ) {
    const eventName = manualAddressEventNames.ADDED;
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = {
      addressFirstLine: addressFirstLine,
      addressCity: addressCity,
      addressStreet: addressStreet,
      addressIndex: addressPostalCode,
      addressHouseNumber: addressHouseNumber,
      addressCountry: addressCountry,
      addressHouseNumberHidden: addressHouseNumberHidden,
      addressApartmentNumber: addressApartmentNumber,
      hasApartmentNumber: hasApartmentNumber,
      source: source,
    };
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackRolePickerOpen: function ({ getters }) {
    const eventName = rolePickerEventNames.OPEN;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {};

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackRolePickerSelectRole: function ({ getters }, { newRole }) {
    const eventName = rolePickerEventNames.SELECT_ROLE;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      newRole: newRole,
    };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackRolePickerSwitchRole: function ({ getters }, { newRole, oldRole }) {
    const eventName = rolePickerEventNames.SWITCH_ROLE;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      newRole: newRole,
      currentRole: oldRole,
    };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackDropdownMenuOpen: function ({ getters }, { menuType }) {
    const eventName = navigationEventNames.OPEN_DROPDOWN_MENU;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      menuType: menuType,
    };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackDropdownMenuClose: function ({ getters }, { menuType }) {
    const eventName = navigationEventNames.CLOSE_DROPDOWN_MENU;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      menuType: menuType,
    };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackNavigationMenuItemClick: function ({ getters }, { url }) {
    const eventName = navigationEventNames.CLICK_NAVIGATION_ITEM;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      url: url,
    };
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackSignInAddInfoEvent: function ({ getters }, { addedInformation }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    let finalProps = {};
    let eventName = '';

    for (const field in addedInformation) {
      finalProps = { value: addedInformation[field] };
      if (field === 'firstName') {
        eventName = sessionEventName.ADDED_FIRST_NAME;
      } else if (field === 'lastName') {
        eventName = sessionEventName.ADDED_LAST_NAME;
      } else if (field === 'acceptedTermsAndConditions') {
        eventName = sessionEventName.TERMS;
      } else if (field === 'phoneNumber') {
        eventName = sessionEventName.ADDED_PHONE_NUMBER;
      }
      const nuxtApp = useNuxtApp();
      return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
    }
  },

  trackLoginEvent: function ({ getters }, { sessionFlow }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = {
      sessionFlow: sessionFlow,
    };
    const eventName = sessionEventName.LOGIN;

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackLoginStartEvent: function ({ getters }, { sessionFlow }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = { sessionFlow: sessionFlow };
    const eventName = sessionEventName.OPEN_MODAL;

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackLoginWithEmail: function ({ getters }, { eventName, sessionFlow, customProps }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = { sessionFlow: sessionFlow, ...customProps };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackLogoutReturningUserEvent: function ({ getters }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = {};
    const eventName = sessionEventName.LOGOUT_DUE_TO_EXPIRED;

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackActivityHubOpen: function (
    { getters },
    { totalNotificationsCount, uninteractedNotificationsCount },
  ) {
    const eventName = activityHubEventNames.OPEN;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      totalNotificationsCount: totalNotificationsCount,
      uninteractedNotificationsCount: uninteractedNotificationsCount,
    };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackActivityHubClose: function ({ getters }) {
    const eventName = activityHubEventNames.CLOSE;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {};

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackActivityHubClearAll: function ({ getters }) {
    const eventName = activityHubEventNames.CLEAR_ALL;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {};

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackActivityHubClickNotification: function (
    { getters },
    { type, created, firstInteractedTime, notificationId },
  ) {
    const eventName = activityHubEventNames.CLICK_NOTIFICATION;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      type: type,
      created: created,
      notificationId: notificationId,
      firstInteractedTime: firstInteractedTime,
    };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackTriggersFireTrigger: function ({ getters }, { triggers }) {
    const eventName = feedbackWidgetEventNames.FIRE_TRIGGER;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      triggers: triggers,
    };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  _trackFeedbackWidget: function ({ getters }, { eventName, props = {} }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, props, finalOptions);
  },
  trackFeedbackWidgetShowButton: function ({ dispatch }) {
    return dispatch('_trackFeedbackWidget', {
      eventName: feedbackWidgetEventNames.SHOW_BUTTON,
    });
  },
  trackFeedbackWidgetOpenModal: function ({ dispatch }) {
    return dispatch('_trackFeedbackWidget', {
      eventName: feedbackWidgetEventNames.OPEN_MODAL,
    });
  },
  trackFeedbackWidgetSubmitMainTopic: function ({ dispatch }, { topic }) {
    const props = {
      topic: topic,
    };

    return dispatch('_trackFeedbackWidget', {
      eventName: feedbackWidgetEventNames.SUBMIT_MAIN_TOPIC,
      props: props,
    });
  },
  trackFeedbackWidgetSubmitFeedback: function ({ dispatch }) {
    return dispatch('_trackFeedbackWidget', {
      eventName: feedbackWidgetEventNames.SUBMIT_FEEDBACK,
    });
  },
  trackLiveChatToggle: function ({ getters }, { wasOpened }) {
    let eventName;

    if (wasOpened) {
      eventName = liveChatEventNames.OPEN_CHAT;
    } else {
      eventName = liveChatEventNames.CLOSE_CHAT;
    }

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const props = {};

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, props, finalOptions);
  },
  trackTenantProfileEvent: function (
    { getters, rootGetters },
    { eventName, props = {}, options = {} },
  ) {
    const defaultOptions = rootGetters.isENVIsDevelopment
      ? { services: ['MX', 'CIO', 'PostHog'] }
      : { services: ['GA', 'MX', 'PostHog', 'FB', 'CIO'] };
    const finalOptions = { ...defaultOptions, ...options };
    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackTenantProfileEvent '${eventName}' props: `, props);
      console.log(`trackTenantProfileEvent '${eventName}' options: `, finalOptions);
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, props, finalOptions);
  },

  /**
   *
   * @param {string} objectType
   * @param {string} openingLocation
   * @param {string} modalType
   */
  trackInviteLinkOpenSharingEvent: function (
    { getters, rootGetters },
    { objectType, openingLocation, modalType },
  ) {
    const eventName = inviteLinkEventNames.INVITE_LINK_OPEN_SHARE;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      [trackingProperties.EVENT_FLOW_NAME]: objectType,
      [trackingProperties.OBJECT_TYPE]: objectType,
      [trackingProperties.LOCATION]: openingLocation,
      [trackingProperties.MODAL_TYPE]: modalType,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackInviteLinkOpenSharingEvent '${eventName}' props: `, finalProps);
    }

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackInviteLinkCopyEvent: function (
    { getters, rootGetters },
    { objectType, openingLocation, channel },
  ) {
    const eventName = inviteLinkEventNames.INVITE_LINK_COPY;

    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      [trackingProperties.EVENT_FLOW_NAME]: objectType,
      [trackingProperties.OBJECT_TYPE]: objectType,
      [trackingProperties.LOCATION]: openingLocation,
      [trackingProperties.CHANNEL]: channel,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackInviteLinkCopyEvent '${eventName}' props: `, finalProps);
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  _trackLeadGeneratorWidget: function ({ getters }, { eventName, props = {} }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, props, finalOptions);
  },
  trackLeadGeneratorWidgetOpenModal: function ({ dispatch }) {
    return dispatch('_trackLeadGeneratorWidget', {
      eventName: leadGeneratorWidgetEventNames.OPEN_MODAL,
    });
  },
  trackLeadGeneratorWidgetSubmit: function ({ dispatch }, { phone, email, source }) {
    const props = {
      phone: phone,
      email: email,
      source: source,
    };

    return dispatch('_trackLeadGeneratorWidget', {
      eventName: leadGeneratorWidgetEventNames.SUBMIT,
      props: props,
    });
  },
  trackLeadGeneratorWidgetCloseModal: function ({ dispatch }) {
    return dispatch('_trackLeadGeneratorWidget', {
      eventName: leadGeneratorWidgetEventNames.CLOSE_MODAL,
    });
  },
  trackInvitationPageView: function (
    { getters },
    { advertisementId, agreementId, invitationShortCode },
  ) {
    const eventName = invitationTrackingEventNames.VIEW;
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };

    const finalProps = {
      advertisementId: advertisementId,
      agreementId: agreementId,
      code: invitationShortCode,
    };
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  postMergeCustomerIoIdentities: function (
    { getters, dispatch },
    { primaryID, secondaryID },
  ) {
    return dispatch('firebaseRequest', {
      request: requestPostMergeCustomerIoIdentities,
      data: {
        request: {
          primary: primaryID,
          secondary: secondaryID,
        },
      },
    });
  },
  trackClickNavLoginButton: function ({ getters }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = {};
    const eventName = sessionEventName.NAV_LOGIN;

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackClickNavGetStartedButton: function ({ getters }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = {};
    const eventName = sessionEventName.NAV_GET_STARTED;

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackClickNavLogo: function ({ getters }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = {};
    const eventName = navigationEventNames.NAV_LOGO;

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackClickNavDashboardButton: function ({ getters }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = {};
    const eventName = navigationEventNames.NAV_DASHBOARD;

    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },
  trackDashboardActivity: function ({ getters }, { eventName }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = {};
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackMarketingEvent: function ({ getters, rootGetters }, { eventName, customProps }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalProps = {
      ...customProps,
    };

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackMarketingEvent '${eventName}' props: `, finalProps);
    }
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, defaultOptions);
  },

  trackAgreementDraftForm: function ({ getters }, { eventName, props }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = { ...props };
    const nuxtApp = useNuxtApp();
    return nuxtApp.$trackEvent(eventName, finalProps, finalOptions);
  },

  trackPriceCalculatorEvent: function ({ getters, rootGetters }, { eventName, props }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = { ...props };
    const nuxtApp = useNuxtApp();
    nuxtApp.$trackEvent(eventName, finalProps, finalOptions);

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackPriceCalculatorEvent '${eventName}' props: `, finalProps);
    }
  },
  trackSessionLengthEvent: function ({ getters, rootGetters }, { eventName, props }) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = { ...props };
    const nuxtApp = useNuxtApp();
    nuxtApp.$trackEvent(eventName, finalProps, finalOptions);

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackSessionLengthEvent '${eventName}' props: `, finalProps);
    }
  },

  trackListingDescriptionTrigger: function (
    { getters, rootGetters },
    { eventName, props },
  ) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = { ...props };
    const nuxtApp = useNuxtApp();
    nuxtApp.$trackEvent(eventName, finalProps, finalOptions);

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackListingDescriptionTrigger '${eventName}' props: `, finalProps);
    }
  },

  trackListingSubscriptionTrigger: function (
    { getters, rootGetters },
    { eventName, props },
  ) {
    const defaultOptions = getters.getDefaultTrackerProviderOptions;
    const finalOptions = { ...defaultOptions };
    const finalProps = { ...props };
    const nuxtApp = useNuxtApp();
    nuxtApp.$trackEvent(eventName, finalProps, finalOptions);

    if (rootGetters.isENVIsDevelopment) {
      console.log(`trackListingSubscriptionTrigger '${eventName}' props: `, finalProps);
    }
  },
};

export const getters = {
  getAnalyticEventNames: () => ({
    DIGIBROKER_SEARCH_TENANT: 'Digibroker: Search Tenant',
    DIGIBROKER_HAS_TENANT: 'Digibroker: Has Tenant',
    DIGIBROKER_ADD_CONTACTS: 'Digibroker: Add Contacts',
    DIGIBROKER_ADD_PROPERTY_DETAILS: 'Digibroker: Add Property Details',
    DIGIBROKER_ADD_PROPERTY: 'Digibroker: Add Property',
    DIGIBROKER_ADD_PRICE: 'Digibroker: Add Price',
    DIGIBROKER_ADD_DESCRIPTION: 'Digibroker: Add Description',
    DIGIBROKER_ADD_IMAGE: 'Digibroker: Add Image',
    DIGIBROKER_REMOVE_IMAGE: 'Digibroker: Remove Image',
    DIGIBROKER_PREVIEW_AD: 'Digibroker: Preview Ad',
    DIGIBROKER_FINALIZE_AD: 'Digibroker: Finalize Ad',
    DIGIBROKER_GO_BACK: 'Digibroker: Go Back',
    ADVERTISEMENT_CHANGE_PROPERTY: 'Advertisement: Change Property',
    ADVERTISEMENT_CHANGE_PRICE: 'Advertisement: Change Price',
    ADVERTISEMENT_CHANGE_DESCRIPTION: 'Advertisement: Change Description',
    ADVERTISEMENT_CHANGE_IMAGES: 'Advertisement: Change Images',
    TENANT_PROFILE_EDIT_PERSONAL_INFORMATION:
      'Tenant Profile: Edit Personal Information',
    TENANT_PROFILE_EDIT_PROFILE_PICTURE: 'Tenant Profile: Edit Profile Picture',
    TENANT_PROFILE_EDIT_ABOUT_YOU: 'Tenant Profile: Edit About You',
    TENANT_PROFILE_EDIT_EDUCATION_AND_EMPLOYMENT:
      'Tenant Profile: Edit Education & Employment',
    TENANT_PROFILE_EDIT_COTENANTS: 'Tenant Profile: Edit Co-tenants',
    TENANT_PROFILE_SUBMIT_BACKGROUND_CHECK: 'Tenant Profile: Submit Background Check',
    ADVERTISEMENT_DUPLICATE_LISTING: 'Advertisement: Duplicate Listing',
  }),
  getAnalyticEventProperties: () => ({
    STEP: 'step',
    NEXT_STEP: 'nextStep',
    SUB_STEP: 'subStep',
    STEP_DIRECTION: 'stepDirection',
    PROPERTY_TYPE: 'propertyType',
    DIGIBROKER_HAS_TENANT: 'hasTenant',
    EVENT_FLOW_NAME: 'processName',
    ADVERTISEMENT_KEY: 'advertisementFirebaseKey',
    FROM_ADVERTISEMENT_KEY: 'from advertisementFirebaseKey',
    AGREEMENT_KEY: 'agreementFirebaseKey',
  }),

  getAnalyticEventFlowNames: () => ({
    DIGIBROKER: 'Digibroker',
    TENANT_PROFILE: 'Tenant Profile',
  }),
  getDefaultTrackerProviderOptions: (state, getters, rootState, rootGetters) => {
    return rootGetters.isENVIsDevelopment
      ? { services: ['CIO', 'POSTHOG', 'GTAG'] }
      : { services: ['POSTHOG', 'GTAG', 'FB', 'CIO'] };
  },
};
