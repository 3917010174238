import { v4 as uuidv4 } from 'uuid';
import { isNil, omitBy } from 'lodash';
import * as Sentry from '@sentry/nuxt';
import {
  systemName,
  providerNames,
  invitationTrackingEventNames,
} from '~/utils/trackerConstants.js';
import { CookieKeys } from '~/utils/constants.js';
import { useStore } from 'vuex';

const removeUndefinedAndNullParametersFromObjectAndReturnNew = (o = {}) =>
  omitBy({ ...o }, isNil);

const tenantOnlyEvents = [
  invitationTrackingEventNames.VIEW,
  invitationTrackingEventNames.APPLY,
  invitationTrackingEventNames.START_LOGIN,
  invitationTrackingEventNames.USE_GALLERY,
  invitationTrackingEventNames.CANCEL,
  invitationTrackingEventNames.CLICK_RENDIN_FEE_INFO_MODAL,
  invitationTrackingEventNames.CLICK_WHY_NO_DEPOSIT,
  invitationTrackingEventNames.SUBMIT_BACKGROUND_CHECK,
  invitationTrackingEventNames.FILL_PROFILE,
  searchTrackingEventNames.SEARCH_LISTINGS,
  searchTrackingEventNames.SUGGESTIONS_FETCH,
  searchTrackingEventNames.SUGGESTIONS_SORT,
  searchTrackingEventNames.SUGGESTIONS_CLICK,
];

export default defineNuxtPlugin({
  name: 'tracker',
  async setup(nuxtApp) {
    const store = useStore();
    const config = useRuntimeConfig();

    const sendError = (err, services) => {
      // Sentry
      if (services || services.includes(providerNames.SENTRY)) {
        Sentry.captureException(err);
      }
    };

    const trackEvent = async (eventName, props, options) => {
      const superProperties = await store.dispatch(
        'tracker/buildSuperPropertiesBasedOnStore',
      );
      const finalProperties = {
        ...superProperties,
        host: typeof window !== 'undefined' ? window.location.host : '',
        userAgent:
          typeof window !== 'undefined' && window.navigator
            ? window.navigator.userAgent
            : '',
        system: systemName,
        eventId: uuidv4(),
        analyticsTools: options.services,
        ...props,
      };

      delete finalProperties.token;

      // PostHog
      if (!options.services || options.services.includes(providerNames.POSTHOG)) {
        try {
          if (import.meta.client) {
            if (eventName === 'Pageview') {
              nuxtApp.$posthog.capture('$pageview', finalProperties);
            } else {
              nuxtApp.$posthog.capture(eventName, finalProperties);
            }
          } else {
            console.log(`PostHog trackEvent called from ssr --> ${eventName}`);
          }
        } catch (err) {
          sendError(err, [providerNames.SENTRY], {
            reason: providerNames.POSTHOG,
          });
        }
      }

      // GTAG
      if (!options.services || options.services.includes(providerNames.GTAG)) {
        /* Events here will be filtered out (to avoid duplication / data collection requirements) */
        const excludedEvents = ['Pageview'];

        if (!excludedEvents.includes(eventName)) {
          try {
            if (import.meta.client) {
              nuxtApp.$gtag('event', eventName);
            } else {
              console.log(`GTAG trackEvent called from ssr --> ${eventName}`);
            }
          } catch (err) {
            sendError(err, [providerNames.GTAG], {
              reason: providerNames.GTAG,
            });
          }
        }
      }

      // Facebook Pixel
      if (
        !options.services ||
        options.services.includes(providerNames.FACEBOOK_PIXEL)
      ) {
        const isTenantEvent = tenantOnlyEvents.includes(eventName);

        const pixelId = isTenantEvent
          ? config.public.metapixel.tenant.id
          : config.public.metapixel.landlord.id;
        try {
          nuxtApp.$fbq('trackSingleCustom', pixelId, eventName, finalProperties);
        } catch (err) {
          sendError(err, [providerNames.SENTRY], {
            reason: providerNames.FACEBOOK_PIXEL,
          });
        }
      }

      // CustomerIO
      // TODO: Move all CIO calls under Tracker and/or generalize beyond current trackerto make it possible
      if (
        !import.meta.server &&
        typeof nuxtApp.$cio === 'object' &&
        (!options.services || options.services.includes(providerNames.CIO))
      ) {
        try {
          /** Add full url & UTM parameters for CustomerIO (other SDK's pull these themselves) */
          const url = new URL(window.location.href);
          const params = new URLSearchParams(url.search);
          const cioProperties = {
            ...finalProperties,
            current_url: window.location.href,
            utm_source: params.get('utm_source'),
            utm_medium: params.get('utm_medium'),
            utm_campaign: params.get('utm_campaign'),
            utm_content: params.get('utm_content'),
          };

          nuxtApp.$cio.track(eventName, cioProperties);
        } catch (err) {
          sendError(err, [providerNames.SENTRY], {
            reason: providerNames.CIO,
          });
        }
      }
    };

    const remapUserProperties = (properties) => {
      const genericProperties = {
        $email: properties.email,
        $phone: properties.phoneNumberFull,
        $first_name: properties.firstName,
        $last_name: properties.lastName,
        firebaseUuid: properties.firebaseUuid,
        firebaseIsAnonymous: properties.firebaseIsAnonymous,
        firebaseIsEmailVerified: properties.firebaseIsEmailVerified,
        is_dev: properties.isDev,
        tenant_check: properties.tenantCheck,
        country: properties.country,
        locale: properties.locale,
        market: properties.market,
        source: properties.source,
      };

      const cioProperties = {
        email: properties.email,
        first_name: properties.firstName,
        last_name: properties.lastName,
      };

      return {
        ...genericProperties,
        ...cioProperties,
      };
    };

    /**
     * TODO: Would it ever make sense to call this without identify? Is it even possible?
     */
    const setUserProperties = ({
      email = undefined,
      phoneNumberFull = undefined,
      profileId = undefined,
      identify = false,
      services = [],
      internalUserProperties = {},
    }) => {
      const mappedProperties = remapUserProperties(internalUserProperties);

      // PostHog
      if (!services || services.includes(providerNames.POSTHOG)) {
        if (import.meta.client) {
          if (identify && email) {
            const currentDistinctId = nuxtApp.$posthog.get_distinct_id();
            const parsedDistinctId = email.toLowerCase().replace('+', '__plus__');

            if (currentDistinctId !== parsedDistinctId) {
              nuxtApp.$posthog.alias(
                nuxtApp.$posthog.get_distinct_id(),
                email.toLowerCase().replace('+', '__plus__'),
              );
            }
          }

          nuxtApp.$posthog.people.set(mappedProperties);
        } else {
          console.log('PostHog setUserProperties called from ssr');
        }
      }

      // GTAG (GA4, GA3, GoogleAds)
      if (!services || services.includes(providerNames.GTAG)) {
        /** NEVER identify with email nor with ANY OTHER PII for GTAG ! */
        if (identify && profileId) {
          // Drop ALL props; cos filtering for ONLY PII is hard
          nuxtApp.$gtag('set', 'user_id', profileId);
        }
      }

      // CIO
      if (!services || services.includes(providerNames.CIO)) {
        if (identify && (email || phoneNumberFull)) {
          identifyForCustomerIo({
            email: email,
            phone: phoneNumberFull,
            properties: mappedProperties,
            hasBeenAuth: store.getters['users/hasSessionUser'],
          });
        }
      }
    };

    const identifyForCustomerIo = ({
      email = undefined,
      phone = undefined,
      properties = {},
      hasBeenAuth = false,
      cioClient,
    }) => {
      /* Any other relevant identifying information */
      const rawCookie = useCookie(CookieKeys.CIO_TEMP_ID).value;
      const generatedCookie = rawCookie ?? { id: uuidv4() };

      const cleanedProperties =
        removeUndefinedAndNullParametersFromObjectAndReturnNew(properties);

      if (hasBeenAuth) {
        const identity = removeUndefinedAndNullParametersFromObjectAndReturnNew({
          id: email,
          email: email,
          $phone: phone,
          ...cleanedProperties,
        });

        nuxtApp.$cio.identify(identity);

        const hasCookie = !!rawCookie?.id;
        if (hasCookie) {
          store.dispatch('tracker/postMergeCustomerIoIdentities', {
            primaryID: email,
            secondaryID: rawCookie.id,
          });
          const cookie = useCookie(CookieKeys.CIO_TEMP_ID);
          cookie.value = undefined;
        }
      } else if (generatedCookie?.id) {
        /* TODO - this is temporary safeguard until we implement phone-based matching in Server in the next iteration */
        // Use this temporary-id approach for users with just phone number
        if (email) {
          return;
        }

        /* When user has not been authenticated in our server, we generate temporary ID to "identify" them in customer.io */
        const identity = removeUndefinedAndNullParametersFromObjectAndReturnNew({
          id: generatedCookie.id,
          $phone: phone,
          ...cleanedProperties,
        });

        nuxtApp.$cio.identify(identity);
        const cioTempCookie = useCookie(CookieKeys.CIO_TEMP_ID, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
          sameSite: 'none',
        });

        cioTempCookie.value = generatedCookie;
      }
    };

    const getDistinctId = () => {
      // Posthog
      return nuxtApp.$posthog.get_distinct_id();
    };

    const resetIdentity = () => {
      if (import.meta.client) {
        nuxtApp.$posthog.reset();
        nuxtApp.$posthog.reloadFeatureFlags();
        nuxtApp.$cio.reset();

        const cookie = useCookie(CookieKeys.CIO_TEMP_ID);
        cookie.value = undefined;
      }
    };

    return {
      provide: {
        trackEvent: trackEvent,
        sendError: sendError,
        setUserProperties: setUserProperties,
        getDistinctId: getDistinctId,
        resetIdentity: resetIdentity,
      },
    };
  },
});
