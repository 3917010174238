<template>
  <section class="handover-protocol-view max-w-5xl mx-auto w-full px-4 pt-2">
    <div
      v-if="
        isTenantView &&
        agreementHandover?.handover?.status === handoverStatuses.COMPLETED
      "
      class="print:hidden bg-blue-50 rounded-lg shadow-lg p-4 sm:px-8 mb-4"
    >
      <div class="text-lg font-medium">
        {{ $t('agreement.handover_v2.tenant_review.please_review') }}
      </div>
      <div class="break-words whitespace-pre-line mt-4">
        {{ $t('agreement.handover_v2.tenant_review.please_review_sub') }}
      </div>
    </div>

    <!--        Address and parties, print only -->
    <div class="hidden print:block">
      <div class="text-3xl font-medium px-4 sm:px-8 my-2">
        {{ $t('agreement.handover_v2.label') }}
      </div>

      <!--                Property information           -->
      <div class="p-4 sm:px-8">
        <div class="flex items-center my-2">
          <r-icon class="text-rendin-700 mr-2" icon-name="building" />
          <span class="text-rendin-700 uppercase font-medium">{{
            $t('agreement.label.rental_object_properties')
          }}</span>
        </div>
        <div class="mt-3 rounded-lg border-2 p-4 sm:px-8">
          <r-title-with-icon
            class="my-2"
            :label="$t('form.label.address')"
            :title="parseFullAddressFromAddressParameters(agreementFromFirebase)"
          />
          <r-title-with-icon
            class="my-2"
            :label="$t('agreement.card.label.contract_id')"
            :title="agreementFromFirebase?.agreementId"
          />
        </div>
      </div>

      <!--                Parties           -->
      <div class="p-4 sm:px-8 my-2">
        <div class="flex items-center my-2">
          <r-icon class="text-rendin-700 mr-2" icon-name="user-group" />
          <span class="text-rendin-700 uppercase font-medium">{{
            $t('agreement.label.add_parties')
          }}</span>
        </div>

        <div class="md:grid grid-cols-2 mt-3 rounded-lg border-2 p-4 sm:px-8">
          <div>
            <div class="font-medium my-2">
              {{ $t('agreement.card.label.landlord') }}
            </div>
            <div v-if="agreementFromFirebase.landlord">
              <r-title-with-icon
                class="my-2"
                icon="user"
                icon-class="text-gray-400"
                :title="constructAgreementName(agreementFromFirebase.landlord)"
              />
              <r-title-with-icon
                class="my-2"
                icon="mobile"
                icon-class="text-gray-400"
                :title="
                  constructPhoneNr(
                    agreementFromFirebase.landlord.phoneCountryCode,
                    agreementFromFirebase.landlord.phoneNumber,
                  )
                "
              />
              <r-title-with-icon
                class="my-2"
                icon="at"
                icon-class="text-gray-400"
                :title="
                  agreementFromFirebase.landlord.email
                    ? agreementFromFirebase.landlord.email
                    : ''
                "
              />
            </div>
            <div v-else class="text-gray-600 text-sm">
              {{ $t('agreement.error.missing_landlord') }}
            </div>
          </div>
          <div>
            <div class="font-medium my-2">{{ $t('agreement.card.label.tenant') }}</div>
            <div v-if="agreementFromFirebase.tenant">
              <r-title-with-icon
                class="my-2"
                icon="user"
                icon-class="text-gray-400"
                :title="
                  agreementFromFirebase.tenant.firstName &&
                  agreementFromFirebase.tenant.firstName
                    ? agreementFromFirebase.tenant.firstName +
                      ' ' +
                      agreementFromFirebase.tenant.lastName
                    : ''
                "
              />
              <r-title-with-icon
                class="my-2"
                icon="mobile"
                icon-class="text-gray-400"
                :title="
                  constructPhoneNr(
                    agreementFromFirebase.tenant.phoneCountryCode,
                    agreementFromFirebase.tenant.phoneNumber,
                  )
                "
              />
              <r-title-with-icon
                class="my-2"
                icon="at"
                icon-class="text-gray-400"
                :title="
                  agreementFromFirebase.tenant.email
                    ? agreementFromFirebase.tenant.email
                    : ''
                "
              />
            </div>
            <div v-else class="text-gray-600 text-sm">
              {{ $t('agreement.error.missing_tenant') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid gap-2">
      <!--        Condition -->
      <div
        class="bg-white print:bg-transparent rounded-lg shadow-lg print:shadow-none p-4 sm:px-8"
      >
        <div class="flex items-center my-2">
          <r-icon class="text-rendin-700 text-lg w-7" icon-name="hammer" />
          <div class="text-rendin-700 uppercase font-medium">
            {{ $t('agreement.handover_v2.condition.condition_title') }}
          </div>
        </div>

        <div
          class="mt-3 print:border-2 print:rounded-lg print:p-4 print:sm:px-8 break-inside-avoid-page"
        >
          <div class="print:text-xs font-medium mb-1">
            {{ $t('agreement.handover_v2.condition.how_condition') }}
          </div>
          <div class="print:text-xs break-words whitespace-pre-line">
            {{ conditionStatusTranslation }}
          </div>

          <div v-if="conditionComment">
            <div class="text-gray-600 text-sm print:text-xs mb-1 mt-4">
              {{ $t('agreement.handover_v2.comment') }}
            </div>
            <div class="print:text-xs break-words whitespace-pre-line">
              {{ conditionComment }}
            </div>
          </div>
        </div>
      </div>

      <!--        Cleanliness -->
      <div
        class="bg-white print:bg-transparent rounded-lg shadow-lg print:shadow-none p-4 sm:px-8"
      >
        <div class="flex items-center my-2">
          <r-icon class="text-rendin-700 text-lg w-7" icon-name="stars" />
          <div class="text-rendin-700 uppercase font-medium">
            {{ $t('agreement.handover_v2.cleanliness.cleanliness_title') }}
          </div>
        </div>

        <div
          class="mt-3 print:border-2 print:rounded-lg print:p-4 print:sm:px-8 break-inside-avoid-page"
        >
          <div class="font-medium print:text-xs mb-1">
            {{ $t('agreement.handover_v2.cleanliness.how_clean') }}
          </div>
          <div class="print:text-xs break-words whitespace-pre-line">
            {{ cleaningStatusTranslation }}
          </div>

          <div v-if="cleaningComment">
            <div class="text-gray-600 text-sm print:text-xs mb-1 mt-4">
              {{ $t('agreement.handover_v2.comment') }}
            </div>
            <div class="print:text-xs break-words whitespace-pre-line">
              {{ cleaningComment }}
            </div>
          </div>
        </div>
      </div>

      <!--        Meters -->
      <div
        v-if="agreementHandover?.meters?.length > 0"
        class="bg-white print:bg-transparent rounded-lg shadow-lg print:shadow-none p-4 sm:px-8"
      >
        <div class="flex items-center my-2">
          <r-icon class="text-rendin-700 text-lg w-7" icon-name="meter" />
          <div class="text-rendin-700 uppercase font-medium">
            {{ $t('agreement.handover_v2.meters.meters_title') }}
          </div>
        </div>

        <div class="grid gap-2 mt-3">
          <div v-for="meter in agreementHandover?.meters" :key="meter.id">
            <r-handover-card
              :card-title="
                meter.name ||
                handoverTypeTranslation(meter.type, handoverDataTypes.METERS)
              "
              :description="meter.description"
              :description-label="$t('agreement.handover_v2.description')"
              :images-data="meter.files"
              :images-description-label="$t('agreement.handover_v2.image_comments')"
              :value="meter.value"
              value-icon="meter"
            />
          </div>
        </div>
      </div>

      <!--        Rooms -->
      <div
        v-if="agreementHandover?.rooms?.length > 0"
        class="bg-white print:bg-transparent rounded-lg shadow-lg print:shadow-none p-4 sm:px-8"
      >
        <div class="flex items-center my-2">
          <r-icon class="text-rendin-700 text-lg w-7" icon-name="house" />
          <div class="text-lg text-rendin-700 uppercase font-medium">
            {{ $t('agreement.handover_v2.rooms.rooms_title') }}
          </div>
        </div>

        <div class="gap-2 grid mt-3">
          <div v-for="room in agreementHandover?.rooms" :key="room.id">
            <r-handover-card
              :card-title="
                room.name || handoverTypeTranslation(room.type, handoverDataTypes.ROOMS)
              "
              :description="room.description"
              :description-label="$t('agreement.handover_v2.description')"
              :images-data="room.files"
              :images-description-label="$t('agreement.handover_v2.image_comments')"
            />
          </div>
        </div>
      </div>

      <!--        Others -->
      <div
        v-if="agreementHandover?.others?.length > 0"
        class="bg-white print:bg-transparent rounded-lg shadow-lg print:shadow-none p-4 sm:px-8"
      >
        <div class="flex items-center my-2">
          <r-icon
            class="text-rendin-700 text-lg w-7"
            icon-name="file-magnifying-glass"
          />
          <div class="text-rendin-700 uppercase font-medium">
            {{ $t('agreement.handover_v2.others.others_title') }}
          </div>
        </div>
        <div class="gap-2 grid mt-3">
          <div v-for="other in agreementHandover?.others" :key="other.id">
            <r-handover-card
              :card-title="
                other.name ||
                handoverTypeTranslation(other.type, handoverDataTypes.OTHERS)
              "
              :card-title-icon="othersTitleIcon(other.type)"
              :description="other.description"
              :description-label="$t('agreement.handover_v2.description')"
              :images-data="other.files"
              :images-description-label="$t('agreement.handover_v2.image_comments')"
              :value="other.value"
              value-icon="meter"
            />
          </div>
        </div>
      </div>

      <!--        Feedback -->
      <div
        v-if="showFeedbackSection"
        class="bg-white print:bg-transparent rounded-lg shadow-lg print:shadow-none p-4 sm:px-8 relative"
      >
        <div class="flex justify-between">
          <div class="flex items-center my-2">
            <r-icon class="text-rendin-700 text-lg w-7" icon-name="user" />
            <div class="text-rendin-700 uppercase font-medium">
              {{ $t('agreement.handover_v2.feedback.feedback_title') }}
            </div>
          </div>
          <div
            v-if="
              isTenantView &&
              agreementHandover?.handover?.status === handoverStatuses.COMPLETED
            "
            class="print:hidden"
          >
            <r-button
              class="p-3 md:hidden"
              color="black"
              icon="pen"
              icon-class="text-3xl m-1 hover:text-rendin-500 focus:text-rendin-500"
              no-background
              no-padding
              @click="openTenantFeedbackViewClick()"
            />
            <r-button
              border-color="light-gray"
              class="hidden md:block"
              color="black"
              icon="pen"
              icon-class="mr-2"
              inverted
              :label="$t('agreement.handover_v2.more_details')"
              size="small"
              width="small"
              @click="openTenantFeedbackViewClick()"
            />
          </div>
        </div>

        <div class="mt-3">
          <r-handover-card
            :card-title="$t('agreement.handover_v2.feedback.additional_information')"
            :description="agreementHandover?.feedback?.description"
            :description-label="$t('agreement.handover_v2.description')"
            :images-data="agreementHandover?.feedback?.files"
            :images-description-label="$t('agreement.handover_v2.image_comments')"
          />
        </div>
      </div>
    </div>

    <!--    Tenant feedback actions-->
    <div
      v-if="showTenantFeedbackButtons"
      class="print:hidden flex flex-col sm:flex-row justify-end gap-4 mt-6"
    >
      <r-button
        v-if="!showFeedbackSection"
        border-color="light-gray"
        class="w-full sm:w-auto"
        color="black"
        inverted
        :label="$t('agreement.handover_v2.more_details')"
        @click="openTenantFeedbackViewClick()"
      />
      <r-button
        class="w-full sm:w-auto"
        :label="$t('agreement.handover_v2.tenant_accept')"
        @click="openTenantConfirmationModal()"
      />
    </div>

    <!--    Landlord actions-->
    <div
      v-else-if="showLandlordEditButtons"
      class="print:hidden flex flex-col sm:flex-row justify-end gap-4 mt-6"
    >
      <r-button
        border-color="light-gray"
        class="w-full sm:w-auto"
        color="black"
        icon="pen"
        inverted
        :label="$t('agreement.handover_v2.landlord_status.edit_handover')"
        @click="openHandoverEditViewClick()"
      />
      <r-button
        v-if="agreementHandover?.handover?.status === handoverStatuses.READY_TO_SEND"
        class="w-full sm:w-auto"
        :label="$t('agreement.handover_v2.landlord_status.send_to_tenant')"
        @click="sentToTenantClick()"
      />
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex';
import {
  handoverStatuses,
  handoverDataTypes,
  handoverDataOthersTypes,
} from '~/utils/constants.js';
import { constructPhoneNr } from '~/utils/phoneNumberConstructor.js';
import { parseFullAddressFromAddressParameters } from '~/utils/addressCollector.js';
import { constructAgreementName } from '~/utils/agreementNameConstructor.js';
import { handoverTypeTranslation } from '~/composables/agreement/handoverTranslationsConstructor.ts';

export default {
  name: 'HandoverProtocolView',

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    isTenantView: {
      type: Boolean,
      default: true,
    },
    hideActionButtons: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'openTenantConfirmationModal',
    'openTenantFeedbackViewClick',
    'openHandoverEditViewClick',
    'sentToTenantClick',
  ],
  setup() {
    return {
      constructAgreementName,
      constructPhoneNr,
      parseFullAddressFromAddressParameters,
      handoverTypeTranslation,
    };
  },

  data: () => ({
    handoverDataTypes,
    handoverDataOthersTypes,
    handoverStatuses,
  }),

  computed: {
    conditionStatusTranslation() {
      const status = this.agreementHandover?.handover?.condition?.status?.toLowerCase();

      return status ? this.$t(`agreement.handover_v2.condition.${status}`) : null;
    },

    conditionComment() {
      return this.agreementHandover?.handover?.condition?.comment;
    },

    cleaningStatusTranslation() {
      const status = this.agreementHandover?.handover?.cleaning?.status?.toLowerCase();

      return status ? this.$t(`agreement.handover_v2.cleanliness.${status}`) : null;
    },

    cleaningComment() {
      return this.agreementHandover?.handover?.cleaning?.comment;
    },

    showFeedbackSection() {
      const feedbackHasRelevantData =
        this.agreementHandover?.feedback?.description ||
        this.agreementHandover?.feedback?.files?.length;
      const isHandoverWaitingForTenantReview =
        feedbackHasRelevantData &&
        this.isTenantView &&
        this.agreementHandover?.handover?.status === handoverStatuses.COMPLETED;
      const isHandoverFinished =
        feedbackHasRelevantData &&
        this.agreementHandover?.handover?.status === handoverStatuses.FINISHED;

      return isHandoverWaitingForTenantReview || isHandoverFinished;
    },

    showLandlordEditButtons() {
      return this.hideActionButtons
        ? false
        : !this.isTenantView &&
            (this.agreementHandover?.handover?.status === this.handoverStatuses.DRAFT ||
              this.agreementHandover?.handover?.status ===
                this.handoverStatuses.READY_TO_SEND);
    },

    showTenantFeedbackButtons() {
      return this.hideActionButtons
        ? false
        : this.isTenantView &&
            this.agreementHandover?.handover?.status ===
              this.handoverStatuses.COMPLETED;
    },
  },

  mounted() {
    this.getHandover();
  },

  methods: {
    ...mapActions({
      actionGetHandover: 'handover/getHandover',
    }),

    getHandover() {
      this.actionGetHandover({ agreementId: this.agreementId });
    },

    othersTitleIcon(type) {
      const typeToIconMap = {
        [handoverDataOthersTypes.VALUABLES]: 'gem',
        [handoverDataOthersTypes.KEYS]: 'gem',
        [handoverDataOthersTypes.PAYMENTS]: 'coins',
        [handoverDataOthersTypes.DEFECT]: 'house-chimney-crack',
        [handoverDataOthersTypes.DOCUMENT]: 'file',
        [handoverDataOthersTypes.OTHER]: 'plus',
      };

      return typeToIconMap[type];
    },

    openTenantConfirmationModal() {
      this.$emit('openTenantConfirmationModal');
    },
    openTenantFeedbackViewClick() {
      this.$emit('openTenantFeedbackViewClick');
    },
    openHandoverEditViewClick() {
      this.$emit('openHandoverEditViewClick');
    },
    sentToTenantClick() {
      this.$emit('sentToTenantClick');
    },
  },
};
</script>
