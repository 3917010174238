<template>
  <section class="agreement-preview">
    <div v-if="agreementFromFirebase">
      <!--                Property information           -->
      <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8">
        <div class="my-2">
          <r-icon class="text-rendin-700 mr-2" icon-name="building" />
          <span class="text-rendin-700 uppercase font-medium">{{
            $t('agreement.label.rental_object_properties')
          }}</span>
        </div>
        <r-title-with-icon
          class="my-2"
          :label="$t('form.label.address')"
          :title="
            constructAddress(agreementFromFirebase)
              ? constructAddress(agreementFromFirebase)
              : '-'
          "
        />
        <div class="grid md:grid-cols-4 gap-2 mt-2">
          <r-title-with-icon
            class="my-2"
            :label="$t('form.label.address_apartment')"
            :title="
              agreementFromFirebase.addressApartmentNumber
                ? agreementFromFirebase.addressApartmentNumber.toString()
                : '-'
            "
          />
          <r-title-with-icon
            v-if="agreementFromFirebase.numberOfRooms"
            class="my-2"
            :label="$t('form.label.numberOfRooms')"
            :title="
              agreementFromFirebase.numberOfRooms
                ? agreementFromFirebase.numberOfRooms.toString()
                : '-'
            "
          />
          <r-title-with-icon
            class="my-2"
            :label="$t('form.label.total_area_m2')"
            :title="
              agreementFromFirebase.objectArea
                ? agreementFromFirebase.objectArea.toString()
                : '-'
            "
          />
        </div>
        <div class="grid md:grid-cols-4 gap-2 mt-2">
          <r-title-with-icon
            class="my-2"
            :label="$t('form.label.parking_space')"
            :title="agreementFromFirebase.hasParking ? $t('yes') : $t('no')"
          />
          <r-title-with-icon
            class="my-2"
            :label="$t('form.label.hasStorage')"
            :title="agreementFromFirebase.hasStorage ? $t('yes') : $t('no')"
          />
        </div>
        <div class="grid md:grid-cols-4 gap-2 mt-2 items-start">
          <r-title-with-icon
            class="my-2"
            :label="$t('form.label.pets_allowed')"
            :title="agreementFromFirebase.petsAllowed ? $t('yes') : $t('no')"
          />
          <div v-if="agreementFromFirebase.petsComment" class="md:col-span-3 my-2">
            <div class="text-gray-600 text-xs">
              {{ $t('agreement.label.pets_special_conditions') }}
            </div>
            <div class="break-words whitespace-pre-line">
              {{ agreementFromFirebase.petsComment }}
            </div>
          </div>
        </div>
      </div>

      <!--                Parties           -->
      <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8 my-2">
        <div class="my-2">
          <r-icon class="text-rendin-700 mr-2" icon-name="user-group" />
          <span class="text-rendin-700 uppercase font-medium">{{
            $t('agreement.label.add_parties')
          }}</span>
        </div>

        <div class="md:grid grid-cols-2">
          <div>
            <div class="font-medium my-2">
              {{ $t('agreement.card.label.landlord') }}
            </div>
            <div v-if="agreementFromFirebase.landlord">
              <div v-if="agreementFromFirebase.landlord.type === 'BUSINESS'">
                <r-title-with-icon
                  class="my-2"
                  icon="user"
                  icon-class="text-gray-400"
                  :title="constructLandlordNameByType(agreementFromFirebase.landlord)"
                />
              </div>
              <div v-else>
                <r-title-with-icon
                  class="my-2"
                  icon="user"
                  icon-class="text-gray-400"
                  :title="
                    agreementFromFirebase.landlord.firstName &&
                    agreementFromFirebase.landlord.firstName
                      ? agreementFromFirebase.landlord.firstName +
                        ' ' +
                        agreementFromFirebase.landlord.lastName
                      : ''
                  "
                />
              </div>
              <r-title-with-icon
                class="my-2"
                icon="mobile"
                icon-class="text-gray-400"
                :title="
                  agreementFromFirebase.landlord
                    ? constructPhoneNr(
                        agreementFromFirebase.landlord.phoneCountryCode,
                        agreementFromFirebase.landlord.phoneNumber,
                      )
                    : null
                "
              />
              <r-title-with-icon
                class="my-2"
                icon="at"
                icon-class="text-gray-400"
                :title="
                  agreementFromFirebase.landlord.email
                    ? agreementFromFirebase.landlord.email
                    : ''
                "
              />
            </div>
            <div v-else class="text-gray-600 text-sm">
              {{ $t('agreement.error.missing_landlord') }}
            </div>
          </div>
          <div>
            <div class="font-medium my-2">{{ $t('agreement.card.label.tenant') }}</div>
            <div v-if="agreementFromFirebase.tenant">
              <r-title-with-icon
                class="my-2"
                icon="user"
                icon-class="text-gray-400"
                :title="
                  agreementFromFirebase.tenant.firstName &&
                  agreementFromFirebase.tenant.firstName
                    ? agreementFromFirebase.tenant.firstName +
                      ' ' +
                      agreementFromFirebase.tenant.lastName
                    : ''
                "
              />
              <r-title-with-icon
                class="my-2"
                icon="mobile"
                icon-class="text-gray-400"
                :title="
                  agreementFromFirebase.tenant
                    ? constructPhoneNr(
                        agreementFromFirebase.tenant.phoneCountryCode,
                        agreementFromFirebase.tenant.phoneNumber,
                      )
                    : null
                "
              />
              <r-title-with-icon
                class="my-2"
                icon="at"
                icon-class="text-gray-400"
                :title="
                  agreementFromFirebase.tenant.email
                    ? agreementFromFirebase.tenant.email
                    : ''
                "
              />
            </div>
            <div v-else class="text-gray-600 text-sm">
              {{ $t('agreement.error.missing_tenant') }}
            </div>
          </div>
        </div>
      </div>

      <!--                cotenant            -->
      <div
        v-if="
          agreementFromFirebase.coTenants && agreementFromFirebase.coTenants.length > 0
        "
        class="bg-white shadow-lg rounded-lg p-4 sm:px-8 my-2"
      >
        <div class="my-2">
          <r-icon class="text-rendin-700 mr-2" icon-name="user-group" />
          <span class="text-rendin-700 uppercase font-medium">{{
            $t('agreement.label.cotenants')
          }}</span>
        </div>
        <div v-for="(coTenant, index) in agreementFromFirebase.coTenants" :key="index">
          <r-title-with-icon
            class="my-2"
            icon="user"
            icon-class="text-gray-400"
            :title="
              coTenant.firstName && coTenant.lastName
                ? coTenant.firstName + ' ' + coTenant.lastName
                : ''
            "
          />
          <r-title-with-icon
            class="my-2"
            icon="mobile"
            icon-class="text-gray-400"
            :title="constructPhoneNr(coTenant.phoneCountryCode, coTenant.phoneNumber)"
          />
          <r-title-with-icon
            class="my-2"
            icon="at"
            icon-class="text-gray-400"
            :title="coTenant.email ? coTenant.email : ''"
          />
        </div>
      </div>

      <!--                Agreement details               -->
      <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8 my-2">
        <div class="my-2">
          <r-icon class="text-rendin-700 mr-2" icon-name="file-lines" />
          <span class="text-rendin-700 uppercase font-medium">{{
            $t('agreement.label.agreement_details')
          }}</span>
        </div>

        <r-title-with-icon
          class="my-2"
          :label="$t('form.label.agreement_type')"
          :title="
            constructAgreementType(
              agreementFromFirebase?.agreementTypeFromAgreement,
              agreementFromFirebase?.agreementTypeFromResolver,
            )
          "
        />

        <r-title-with-icon
          class="my-2"
          :label="$t('agreement.label.agreement_language')"
          :title="
            agreementFromFirebase.language
              ? constructAgreementLanguage(agreementFromFirebase.language)
              : '-'
          "
        />
        <r-title-with-icon
          class="my-2"
          :label="$t('agreement.label.property_handover_date')"
          :title="
            agreementFromFirebase.startDate
              ? constructDateFromIsoString(
                  agreementFromFirebase.startDate,
                  agreementTimeZone,
                )
              : '-'
          "
        />
        <r-title-with-icon
          v-if="agreementFromFirebase.endDate"
          class="my-2"
          :label="$t('agreement.label.end_date')"
          :title="
            constructDateFromIsoString(agreementFromFirebase.endDate, agreementTimeZone)
          "
        />

        <div v-if="agreementFromFirebase.specialNotes" class="my-2">
          <div class="text-gray-600 text-xs font-medium">
            {{ $t('agreement.label.special_conditions') }}
          </div>
          <div class="break-words whitespace-pre-line">
            {{ agreementFromFirebase.specialNotes }}
          </div>
        </div>
      </div>
      <!--                Rent fee             -->
      <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8 my-2">
        <div class="my-2">
          <r-icon class="text-rendin-700 mr-2" icon-name="money-check-pen" />
          <span class="text-rendin-700 uppercase font-medium">{{
            $t('agreement.label.monthly_rent_fee')
          }}</span>
        </div>
        <r-title-with-icon
          class="my-2"
          :label="`${$t('agreement.label.monthly_rent')} (${agreementFromFirebase.currency})`"
          :title="
            agreementFromFirebase.rentAmount
              ? localizedCurrencyFormat(agreementFromFirebase.rentAmount, getLocale)
              : '-'
          "
        />
        <r-title-with-icon
          class="my-2"
          :label="$t('agreement.label.monthly_rent_payment_day')"
          :title="
            agreementFromFirebase.paymentDay
              ? `${$t('day')} ${agreementFromFirebase.paymentDay}`
              : '-'
          "
        />
      </div>

      <!--                Other fees             -->
      <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8 my-2">
        <div class="my-2">
          <r-icon class="text-rendin-700 mr-2" icon-name="coins" />
          <span class="text-rendin-700 uppercase font-medium">{{
            $t('agreement.label.other_fees')
          }}</span>
        </div>

        <!--        Administrative rent -->
        <div
          v-if="agreementFromFirebase.administrativeFees?.length > 0"
          class="pt-4 border-b last:border-b-0"
        >
          <div class="font-medium">{{ $t('agreement.label.administrative_rent') }}</div>
          <div
            v-for="(fee, index) in agreementFromFirebase.administrativeFees"
            :key="index"
          >
            <r-title-with-icon
              class="my-2"
              :label="$t('administrative_rent.included_or_separate_title')"
              :title="
                fee.paymentIncludedInRent
                  ? $t('administrative_rent.included')
                  : $t('administrative_rent.separate')
              "
            />
            <r-title-with-icon
              v-if="fee.paymentDetermination"
              class="my-2"
              :label="$t('administrative_rent.payment_determination_title')"
              :title="
                fee.paymentDetermination === administrativeFeePaymentDetermination.FIXED
                  ? $t('administrative_rent.yes')
                  : $t('administrative_rent.no')
              "
            />

            <r-title-with-icon
              v-if="fee.paymentAmount"
              class="my-2"
              :label="`${$t('administrative_rent.rent_amount')} (${agreementFromFirebase.currency})`"
              :title="localizedCurrencyFormat(fee.paymentAmount, getLocale)"
            />
            <r-title-with-icon
              v-if="fee.paymentFrequency"
              class="my-2"
              :label="$t('agreement.label.monthly_rent_payment_day')"
              :title="`${$t('day')} ${fee.paymentFrequency}`"
            />
            <div v-if="fee.paymentRecipient" class="my-2">
              <div class="text-gray-600 text-xs font-medium">
                {{ $t('administrative_rent.payer_role_title') }}
              </div>
              <div class="break-words whitespace-pre-line">
                <span
                  v-if="
                    fee.paymentRecipient === administrativeFeePaymentPartyType.LANDLORD
                  "
                >
                  {{ $t('administrative_rent.payer_recipient_description_landlord') }}
                </span>
                <span v-else>
                  {{
                    $t(
                      'administrative_rent.payer_recipient_description_service_provider',
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!--        Services -->
        <div
          v-if="agreementServicesAndUtilitiesFromFirebase?.length > 0"
          class="pt-4 border-b last:border-b-0"
        >
          <div class="font-medium">
            {{ $t(`agreement.label.utilities_services.${getCountry}`) }}
          </div>
          <div v-if="servicesAndUtilitiesByPayerTenantList.length > 0" class="my-3">
            <div class="font-medium text-sm">
              {{ $t('services.label.tenant_pays') }}
            </div>
            <ul class="list-disc pl-6">
              <li
                v-for="(fee, index) in servicesAndUtilitiesByPayerTenantList"
                :key="index"
              >
                {{ servicesAndUtilitiesNames(fee.type, fee.typeName) }}
                <ul class="list-disc pl-6 text-sm">
                  <li
                    v-if="
                      fee.paymentMethod ===
                        administrativeFeePaymentDetermination.FIXED &&
                      fee.payment?.amount
                    "
                  >
                    <span class="text-gray-600">
                      {{ $t('administrative_rent.amount') }}:
                    </span>
                    {{ localizedCurrencyFormat(fee.payment?.amount, getLocale) }}
                    {{ agreementFromFirebase.currency }}
                  </li>
                  <li v-if="fee.payment?.details">
                    <span class="text-gray-600"
                      >{{ $t('agreement.label.payment_details') }}:
                    </span>
                    {{ fee.payment?.details }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div v-if="servicesAndUtilitiesByPayerLandlordList.length > 0" class="my-3">
            <div class="font-medium text-sm">
              {{ $t('services.label.Landlord_pays') }}
            </div>

            <ul class="list-disc pl-6">
              <li
                v-for="(fee, index) in servicesAndUtilitiesByPayerLandlordList"
                :key="index"
              >
                {{ servicesAndUtilitiesNames(fee.type, fee.typeName) }}
                <ul class="list-disc pl-6 text-sm">
                  <li
                    v-if="
                      fee.paymentMethod ===
                        administrativeFeePaymentDetermination.FIXED &&
                      fee.payment?.amount
                    "
                  >
                    <span class="text-gray-600">
                      {{ $t('administrative_rent.amount') }}:
                    </span>
                    {{ localizedCurrencyFormat(fee.payment?.amount, getLocale) }}
                    {{ agreementFromFirebase.currency }}
                  </li>
                  <li v-if="fee.payment?.details">
                    <span class="text-gray-600"
                      >{{ $t('agreement.label.payment_details') }}:
                    </span>
                    {{ fee.payment?.details }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <!--        Agent & contract fees -->
        <div
          v-if="
            agreementFromFirebase.additionalFees.some(
              (e) =>
                e.type ===
                (additionalFeeType.CONTRACT_FEE || additionalFeeType.BROKER_FEE),
            )
          "
          class="pt-4 border-b last:border-b-0"
        >
          <div class="font-medium">{{ $t('agreement.label.agent_contract_fees') }}</div>

          <div v-if="agentAndContractFeesListForTenants.length > 0" class="mt-4">
            <div class="font-medium text-sm">
              {{ $t('services.label.tenant_pays') }}
            </div>
            <div
              v-for="(fee, index) in agentAndContractFeesListForTenants"
              :key="index"
            >
              <r-title-with-icon
                class="my-2"
                :label="constructAdditionalFeeLabel(fee.type, fee.paymentInterval)"
                :title="
                  fee.amount
                    ? localizedCurrencyFormat(fee.amount, getLocale) +
                      ' ' +
                      agreementFromFirebase.currency
                    : ''
                "
              />
            </div>
          </div>

          <div v-if="agentAndContractFeesListForLandlords.length > 0" class="mt-4">
            <div class="font-medium text-sm">
              {{ $t('services.label.Landlord_pays') }}
            </div>
            <div
              v-for="(fee, index) in agentAndContractFeesListForLandlords"
              :key="index"
            >
              <r-title-with-icon
                class="my-2"
                :label="constructAdditionalFeeLabel(fee.type, fee.paymentInterval)"
                :title="
                  fee.amount
                    ? localizedCurrencyFormat(fee.amount, getLocale) +
                      ' ' +
                      agreementFromFirebase.currency
                    : ''
                "
              />
            </div>
          </div>
        </div>
      </div>

      <!--                Payment details             -->
      <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8 my-2">
        <div class="my-2">
          <r-icon class="text-rendin-700 mr-2" icon-name="credit-card" />
          <span class="text-rendin-700 uppercase font-medium">{{
            $t('agreement.label.payment_details')
          }}</span>
        </div>

        <r-title-with-icon
          class="my-2"
          :label="$t('agreement.label.bank_account_number')"
          :title="
            agreementFromFirebase.bankAccountNumber
              ? agreementFromFirebase.bankAccountNumber.toString()
              : '-'
          "
        />
        <r-title-with-icon
          class="my-2"
          :label="$t('agreement.label.bank_account_owner_name')"
          :title="
            agreementFromFirebase.bankAccountOwnerName
              ? agreementFromFirebase.bankAccountOwnerName
              : '-'
          "
        />
      </div>

      <!--                Rendin fee             -->
      <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8 my-2">
        <div class="my-2">
          <r-icon class="text-rendin-700 mr-2" icon-name="piggy-bank" />
          <span class="text-rendin-700 uppercase font-medium">{{
            $t('agreement.label.rendin_fee')
          }}</span>
        </div>

        <r-title-with-icon
          class="my-2"
          :label="$t('agreement.label.rendin_service_fee')"
          :title="
            agreementFromFirebase.insuranceAmount
              ? localizedCurrencyFormat(
                  agreementFromFirebase.insuranceAmount,
                  getLocale,
                ) +
                ' ' +
                agreementFromFirebase.currency
              : '-'
          "
        />
        <r-title-with-icon
          class="my-2"
          :label="$t('agreement.label.rendin_service_fee_payment_day')"
          :title="`${$t('day')}  ${insuranceInvoicePaymentDay.PAYMENT_DAY}`"
        />
        <r-title-with-icon
          class="my-2"
          :label="$t('agreement.label.insurance_invoice_select')"
          :title="
            agreementFromFirebase.insuranceInvoiceTo === insuranceInvoiceTo.TENANT
              ? $t('tenant')
              : $t('landlord')
          "
        />
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  languageNames,
  additionalFeeType,
  administrativeFeePaymentDetermination,
  administrativeFeePaymentPartyType,
  insuranceInvoiceTo,
  insuranceInvoicePaymentDay,
} from '~/utils/constants';
import { constructPhoneNr } from '~/utils/phoneNumberConstructor';
import { constructDateFromIsoString } from '~/utils/dateFromIsoStringConstructor.ts';
import { servicesAndUtilitiesNames } from '~/composables/agreement/servicesAndUtilitiesNames.ts';
import { localizedCurrencyFormat } from '~/utils/localizedCurrencyFormat';
import { constructAgreementType } from '~/composables/agreement/agreementTypeConstructor.ts';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AgreementPreview',
  scrollToTop: true,

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementServicesAndUtilitiesFromFirebase: {
      type: Array,
      default: null,
    },
    agreementTimeZone: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      constructPhoneNr,
      constructDateFromIsoString,
      localizedCurrencyFormat,
      servicesAndUtilitiesNames,
      constructAgreementType,
    };
  },

  data: () => ({
    languageNames,
    additionalFeeType,
    administrativeFeePaymentDetermination,
    administrativeFeePaymentPartyType,
    insuranceInvoiceTo,
    insuranceInvoicePaymentDay,
  }),

  computed: {
    ...mapGetters({
      getCountry: 'getCountry',
      getLocale: 'getLocale',
    }),

    agentAndContractFeesListForTenants() {
      // fallback condition for legacy data structures > fee.payerRole !== 'LANDLORD'
      return this.agreementFromFirebase.additionalFees.filter(
        (fee) =>
          fee.payerRole !== 'LANDLORD' &&
          (fee.type === additionalFeeType.BROKER_FEE ||
            fee.type === additionalFeeType.CONTRACT_FEE),
      );
    },

    agentAndContractFeesListForLandlords() {
      return this.agreementFromFirebase.additionalFees.filter(
        (fee) =>
          fee.payerRole === 'LANDLORD' &&
          (fee.type === additionalFeeType.BROKER_FEE ||
            fee.type === additionalFeeType.CONTRACT_FEE),
      );
    },

    servicesAndUtilitiesByPayerTenantList() {
      return this.agreementServicesAndUtilitiesFromFirebase
        ? this.agreementServicesAndUtilitiesFromFirebase.filter(
            (fee) => fee.whoIsPaying === 'TENANT',
          )
        : [];
    },

    servicesAndUtilitiesByPayerLandlordList() {
      return this.agreementServicesAndUtilitiesFromFirebase
        ? this.agreementServicesAndUtilitiesFromFirebase.filter(
            (fee) => fee.whoIsPaying === 'LANDLORD',
          )
        : [];
    },
  },

  mounted() {
    this.scrollPageUp();
    if (this.agreementId)
      this.actionGetAgreementServicesAndUtilities({ agreementId: this.agreementId });
  },

  methods: {
    ...mapActions({
      actionGetAgreementServicesAndUtilities:
        'agreements/getAgreementServicesAndUtilities',
    }),

    constructAddress(obj) {
      let address = '';
      if (obj) {
        if (obj.addressFirstLine) {
          address = address.concat(obj.addressFirstLine);
        }
        if (obj.addressCity) {
          address = address.concat(`, ${obj.addressCity}`);
        }
        if (obj.addressIndex) {
          address = address.concat(`, ${obj.addressIndex}`);
        }
        if (obj.addressCountry) {
          if (address.length > 0) address = address.concat(', ');
          address = address.concat(obj.addressCountry);
        }
      }

      return address;
    },

    constructAgreementLanguage(lang) {
      let language = '';
      if (lang === 'et-EE') {
        language = languageNames.EE;
      }
      if (lang === 'en-GB') {
        language = languageNames.EN;
      }
      if (lang === 'ru-RU') {
        language = languageNames.RU;
      }
      if (lang === 'pl-PL') {
        language = languageNames.PL;
      }

      return language;
    },

    constructAdditionalFeeLabel(type) {
      let label = '';

      if (type === 'BROKER_FEE') {
        label = label.concat(this.$t('additional_fee.dropdown_types.broker_fee'));
      } else if (type === 'FIXED_UTILITIES') {
        label = label.concat(this.$t('additional_fee.dropdown_types.fixed_utilities'));
      } else if (type === 'CONTRACT_FEE') {
        label = label.concat(this.$t('additional_fee.dropdown_types.contract_fee'));
      }

      return label;
    },

    scrollPageUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    constructLandlordNameByType(landlord) {
      let name = '';
      let firstName = '';
      let lastName = '';

      if (landlord.firstName) firstName = landlord.firstName;
      if (landlord.lastName) lastName = landlord.lastName;
      name = `${firstName} ${lastName}`;

      if (landlord.type === 'BUSINESS' && landlord.companyName) {
        return `${landlord.companyName} (${name})`;
      }

      return name;
    },
  },
});
</script>
