<template>
  <div>
    <div class="grid sm:grid-cols-2 sm:gap-4 sm:items-start">
      <div>
        <Field
          v-slot="{ meta, errors }"
          ref="addressApartmentNumber"
          v-model="addressApartmentNumberModel"
          :validate-on-mount="immediate"
          :rules="{
            isValidHasApartmentAndApartmentNumber: {
              hasApartmentNumber: hasApartmentNumber,
            },
          }"
          name="addressApartmentNumber"
        >
          <r-input
            v-model="addressApartmentNumberModel"
            :data-testid="dataTestid"
            :disabled="notAnApartment"
            :label="label"
            :label-class="labelClass"
            name="apartmentNumber"
            :placeholder="placeholder"
            :required="!notAnApartment"
            :required-label="requiredLabel"
            :validation-failed="
              (!meta.valid && (meta.dirty || meta.touched)) ||
              (!meta.valid && overrideFailed)
            "
            :validation-passed="meta.valid"
            @blur="onBlur"
          />
          <div
            v-if="
              (!meta.valid && (meta.dirty || meta.touched)) ||
              (!meta.valid && overrideFailed)
            "
            class="text-error text-sm"
            :class="{ 'font-medium': flavor === 'medium' }"
          >
            <p v-for="error in errors">{{ error }}</p>
          </div>
        </Field>
      </div>
      <r-checkbox v-model="notAnApartment" class="sm:mt-7" name="checkbox">
        {{ $t('form.label.no_apartment_number') }}
      </r-checkbox>
    </div>
    <div v-if="disclaimer" class="pb-4 sm:pt-4 text-xs sm:text-sm text-gray-500">
      {{ disclaimer }}
    </div>
  </div>
</template>
<script>
import { Field } from 'vee-validate';

export default {
  name: 'InputApartmentNumber',
  components: { Field },
  props: {
    requiredLabel: {
      type: String,
      default: '*',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Flavor for label font weight | [ `default`, `medium`,]
     */
    flavor: {
      type: String,
      default: 'default',
    },
    disclaimer: {
      type: String,
      default: '',
    },
    immediate: {
      type: Boolean,
      required: false,
      default: false,
    },
    addressApartmentNumber: {
      type: String,
      required: false,
      default: '',
    },
    hasApartmentNumber: {
      type: Boolean,
      required: true,
    },
    dataTestid: {
      type: String,
      required: false,
      default: '',
    },
    overrideFailed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'update:addressApartmentNumber',
    'input',
    'update:hasApartmentNumber',
    'blur',
  ],
  computed: {
    hasValidationError() {
      const hasFailedRulesOrGeneralError =
        this.failedRules?.length > 0 || this.validationFailed;

      return !this.hasApartmentNumber === false && hasFailedRulesOrGeneralError;
    },
    addressApartmentNumberModel: {
      get() {
        return this.addressApartmentNumber;
      },
      set(value) {
        this.$emit('update:addressApartmentNumber', value);
        this.$emit('input');
      },
    },
    notAnApartment: {
      get() {
        return !this.hasApartmentNumber;
      },
      set(value) {
        this.$emit('update:hasApartmentNumber', !value);
        this.$emit('input');
        this.$refs.addressApartmentNumber.setTouched(true);
        if (value) {
          this.addressApartmentNumberModel = null;
        }
        this.onBlur();
      },
    },
  },
  methods: {
    onBlur() {
      this.$emit('blur');
    },
  },
};
</script>
