<template>
  <section class="agreement-annex-view flex flex-grow flex-col w-full">
    <div class="max-w-5xl mx-auto p-4 w-full flex-grow">
      <!--    Intro -->
      <div
        v-if="activeAnnexView === annexViewSteps.START"
        class="px-4 pt-4 pb-8 md:pt-12 md:pb-16 flex-grow flex items-center bg-white rounded-lg shadow-lg"
      >
        <div class="grid md:grid-cols-5 items-center gap-4">
          <div class="text-center my-4 md:col-span-2">
            <h3 class="font-medium text-2xl md:text-3xl">
              {{ $t('agreement.annexing.annex') }}
            </h3>
            <img
              :alt="$t('agreement.annexing.annex')"
              class="mx-auto mt-8"
              src="/illustrations/sammy-line-man-with-pencil-and-calendar.svg"
            />
          </div>

          <ul class="md:col-span-3">
            <li class="flex gap-2 items-center py-6 border-b">
              <r-icon
                class="text-gray-700 md:text-lg w-8 shrink-0"
                icon-name="calendar-check"
              />
              <div class="text-gray-600">
                {{ $t('agreement.annexing.renewal_long') }}
              </div>
            </li>
            <li class="flex gap-2 items-center py-6 border-b">
              <r-icon
                class="text-gray-700 md:text-lg w-8 shrink-0"
                icon-name="calendar-day"
              />
              <div class="text-gray-600">
                {{ $t('agreement.annexing.modify_end_date') }}
                <a
                  class="text-rendin-700 font-medium cursor-pointer hover:text-rendin-500 focus:text-rendin-500"
                  href="/contact"
                  target="_blank"
                  >{{ $t('agreement.annexing.customer_service') }}.
                </a>
              </div>
            </li>
            <li class="flex gap-2 items-center py-6 border-b">
              <r-icon
                class="text-gray-700 md:text-lg w-8 shrink-0"
                icon-name="file-signature"
              />
              <div class="text-gray-600">
                {{ $t('agreement.annexing.how_long_extend') }}
              </div>
            </li>
            <li class="flex gap-2 items-center py-6">
              <r-icon
                class="text-gray-700 md:text-lg w-8 shrink-0"
                icon-name="hourglass-start"
              />
              <div class="text-gray-600">
                {{ $t('agreement.annexing.expires_when') }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--    Select Date -->
      <div
        v-else-if="activeAnnexView === annexViewSteps.EDIT"
        class="p-4 bg-white rounded-lg shadow-lg"
      >
        <h3 class="font-medium text-lg my-4">
          {{ $t('agreement.annexing.choose_period') }}
        </h3>
        {{ $t('agreement.annexing.how_long') }}

        <r-radio
          v-model="annexEndDateOption"
          flavor="bordered"
          name="radio-horizontal"
          option-label="label"
          :options="renewalOptions"
          radio-box-right
          value="red"
        >
          <template #YEAR>
            {{ agreementActiveForOneYear }}
          </template>

          <template #CUSTOM>
            <div v-if="annexEndDateOption === annexEndDateOptions.CUSTOM" class="pr-4">
              <LazyRDatepicker
                v-model="annexEndDate"
                :disabled-date="disabledBeforeTodayAnd10YearsAfterStart"
                :error-text="$t('form.validation.pick_date')"
                :placeholder="$t('agreement.annexing.end_date')"
              />
            </div>
          </template>
        </r-radio>
      </div>
      <!--    Preview -->
      <div
        v-else-if="activeAnnexView === annexViewSteps.PREVIEW"
        class="flex flex-col gap-2"
      >
        <!--                Review          -->
        <div v-if="!annex" class="bg-white shadow-lg rounded-lg p-4 sm:p-8">
          <h3 class="font-medium text-lg mb-4">
            {{ $t('agreement.annexing.review') }}
          </h3>
          {{ $t('agreement.annexing.review_long') }}
        </div>

        <!--                Agreement details               -->
        <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8">
          <div class="my-2">
            <r-icon class="text-rendin-700 mr-2" icon-name="file-lines" />
            <span class="text-rendin-700 uppercase font-medium">{{
              $t('agreement.label.agreement_details')
            }}</span>
          </div>

          <r-title-with-icon
            v-if="annex?.new?.endDate || annexEndDate"
            class="my-2"
            :label="$t('agreement.label.end_date')"
            :title="
              constructDateFromIsoString(
                annex?.new?.endDate || annexEndDate,
                agreementTimeZone,
              )
            "
          />
          <r-title-with-icon
            v-if="agreementFromFirebase.agreementId"
            class="my-2"
            :label="$t('agreement.card.label.contract_id')"
            :title="agreementFromFirebase.agreementId"
          />

          <r-title-with-icon
            class="my-2"
            :label="$t('form.label.agreement_type')"
            :title="
              constructAgreementType(
                agreementFromFirebase.agreementTypeFromAgreement,
                agreementFromFirebase.agreementTypeFromResolver,
              )
            "
          />
        </div>

        <!--                Parties           -->
        <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8">
          <div class="my-2">
            <r-icon class="text-rendin-700 mr-2" icon-name="user-group" />
            <span class="text-rendin-700 uppercase font-medium">{{
              $t('agreement.label.add_parties')
            }}</span>
          </div>

          <div class="md:grid grid-cols-2">
            <div>
              <div class="font-medium my-2">
                {{ $t('agreement.card.label.landlord') }}
              </div>
              <div v-if="agreementFromFirebase.landlord">
                <r-title-with-icon
                  class="my-2"
                  icon="user"
                  icon-class="text-gray-400"
                  :title="constructAgreementName(agreementFromFirebase.landlord)"
                />
                <r-title-with-icon
                  class="my-2"
                  icon="mobile"
                  icon-class="text-gray-400"
                  :title="
                    agreementFromFirebase.landlord
                      ? constructPhoneNr(
                          agreementFromFirebase.landlord.phoneCountryCode,
                          agreementFromFirebase.landlord.phoneNumber,
                        )
                      : null
                  "
                />
                <r-title-with-icon
                  class="my-2"
                  icon="at"
                  icon-class="text-gray-400"
                  :title="
                    agreementFromFirebase.landlord.email
                      ? agreementFromFirebase.landlord.email
                      : ''
                  "
                />
              </div>
              <div v-else class="text-gray-600 text-sm">
                {{ $t('agreement.error.missing_landlord') }}
              </div>
            </div>
            <div>
              <div class="font-medium my-2">
                {{ $t('agreement.card.label.tenant') }}
              </div>
              <div v-if="agreementFromFirebase.tenant">
                <r-title-with-icon
                  class="my-2"
                  icon="user"
                  icon-class="text-gray-400"
                  :title="constructAgreementName(agreementFromFirebase.tenant)"
                />
                <r-title-with-icon
                  class="my-2"
                  icon="mobile"
                  icon-class="text-gray-400"
                  :title="
                    agreementFromFirebase.tenant
                      ? constructPhoneNr(
                          agreementFromFirebase.tenant.phoneCountryCode,
                          agreementFromFirebase.tenant.phoneNumber,
                        )
                      : null
                  "
                />
                <r-title-with-icon
                  class="my-2"
                  icon="at"
                  icon-class="text-gray-400"
                  :title="
                    agreementFromFirebase.tenant.email
                      ? agreementFromFirebase.tenant.email
                      : ''
                  "
                />
              </div>
              <div v-else class="text-gray-600 text-sm">
                {{ $t('agreement.error.missing_tenant') }}
              </div>
            </div>
          </div>
        </div>

        <!--                What comes next           -->
        <div v-if="!annex" class="bg-white shadow-lg rounded-lg p-4 sm:p-8">
          <h3 class="font-medium text-lg mb-4">
            {{ $t('agreement.annexing.what_next') }}
          </h3>
          <ul class="flex flex-col gap-4">
            <li>1. <span v-html="$t('agreement.annexing.first_step')"></span></li>
            <li>2. <span v-html="$t('agreement.annexing.second_step')"></span></li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="!annex" class="bg-white mt-4 w-full">
      <div class="max-w-5xl mx-auto flex justify-between gap-4 px-4 py-6">
        <div>
          <r-button
            v-if="annexViewStepsOrder.indexOf(activeAnnexView) > 0"
            border-color="light-gray"
            class="h-full"
            color="black"
            icon="arrow-left-long"
            icon-class="ml-1"
            inverted
            :label="$t('buttons.back')"
            label-class="hidden sm:block"
            @click="prevStep()"
          />
        </div>

        <r-button
          :disabled="!annexEndDate && activeAnnexView === annexViewSteps.EDIT"
          :label="nextButtonLabel"
          @click="
            activeAnnexView === annexViewSteps.PREVIEW
              ? openSignAnnexModal()
              : nextStep()
          "
        />
      </div>
    </div>

    <AnnexSignModal
      :agreement-from-firebase="agreementFromFirebase"
      :annex-end-date="annexEndDate"
      :show-modal="showSignAnnexModal"
      @close-annex-view="closeAnnexView"
      @close-overlay="showSignAnnexModal = false"
    />
  </section>
</template>
<script>
import { DateTime } from 'luxon';
import { mapActions } from 'vuex';
import { constructPhoneNr } from '~/utils/phoneNumberConstructor.js';
import { constructDateFromIsoString } from '~/utils/dateFromIsoStringConstructor.ts';
import AnnexSignModal from '~/components/section/agreement/annex/AnnexSignModal.vue';
import {
  agreementTrackingEventNames,
  trackingProcessNames,
  trackingProperties,
} from '~/utils/trackerConstants.js';
import { constructAgreementName } from '~/utils/agreementNameConstructor.js';
import { constructAgreementType } from '~/composables/agreement/agreementTypeConstructor.ts';
import { defineComponent } from 'vue';

const annexViewSteps = {
  START: 'START',
  EDIT: 'EDIT',
  PREVIEW: 'PREVIEW',
};
export const annexViewStepsOrder = [
  annexViewSteps.START,
  annexViewSteps.EDIT,
  annexViewSteps.PREVIEW,
];

const annexEndDateOptions = {
  YEAR: 'YEAR',
  CUSTOM: 'CUSTOM',
};

export default defineComponent({
  name: 'AgreementAnnexView',

  components: {
    AnnexSignModal,
  },

  props: {
    annex: {
      type: Object,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementTimeZone: {
      type: String,
      default: null,
    },
  },

  emits: ['closeAnnexView'],

  setup() {
    return {
      constructPhoneNr,
      constructDateFromIsoString,
      constructAgreementName,
      constructAgreementType,
    };
  },

  data: (vm) => ({
    annexViewSteps,
    activeAnnexView: annexViewSteps.START,
    annexEndDateOptions,
    annexViewStepsOrder,
    renewalOptions: [
      {
        value: annexEndDateOptions.YEAR,
        label: vm.$t('agreement.annexing.one_year'),
      },
      {
        value: annexEndDateOptions.CUSTOM,
        label: vm.$t('agreement.annexing.custom_time'),
      },
    ],
    annexEndDateOption: annexEndDateOptions.YEAR,
    annexEndDate: null,

    showSignAnnexModal: false,
  }),

  computed: {
    agreementActiveForOneYear() {
      const parameters = {
        start_date: this.constructDateFromIsoString(
          this.agreementFromFirebase.startDate,
          this.agreementTimeZone,
        ),
        end_date: this.constructDateFromIsoString(
          this.setDateOneYearAfter,
          this.agreementTimeZone,
        ),
      };

      return this.$t('agreement.annexing.agreement_active_from', parameters);
    },

    nextButtonLabel() {
      if (this.activeAnnexView === annexViewSteps.START) {
        return this.$t('buttons.start');
      } else if (this.activeAnnexView === annexViewSteps.EDIT) {
        return this.$t('buttons.next');
      }
      return this.$t('agreement.annexing.sign_annex');
    },

    setDateOneYearAfter() {
      const date = DateTime.fromISO(this.agreementFromFirebase.endDate, {
        zone: 'Europe/Warsaw',
      })
        .plus({ years: 1 })
        .startOf('day');

      return date.toISO();
    },
  },

  watch: {
    annex(value) {
      this.activeAnnexView = value ? annexViewSteps.PREVIEW : annexViewSteps.START;
    },

    activeAnnexView(newVal, oldVal) {
      this.scrollPageUp();

      if (oldVal === annexViewSteps.EDIT && newVal === annexViewSteps.PREVIEW) {
        const endDateProp = {
          endDate: this.annexEndDate,
        };
        this.emitAgreementTrackerEvent(
          agreementTrackingEventNames.ANNEX_NEW_END_DATE,
          endDateProp,
        );
      }
      if (newVal === annexViewSteps.START) {
        this.emitAgreementTrackerEvent(
          agreementTrackingEventNames.ANNEX_READ_INSTRUCTIONS,
        );
      }
    },
  },

  mounted() {
    this.activeAnnexView = this.annex ? annexViewSteps.PREVIEW : annexViewSteps.START;
    this.annexEndDate = this.setDateOneYearAfter;

    if (!this.annex) {
      this.emitAgreementTrackerEvent(
        agreementTrackingEventNames.ANNEX_READ_INSTRUCTIONS,
      );
    }
  },

  methods: {
    ...mapActions({
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    nextStep() {
      if (!this.annexEndDate && this.activeAnnexView === annexViewSteps.EDIT) return;

      const currentIndex = this.annexViewStepsOrder.indexOf(this.activeAnnexView);
      if (currentIndex < this.annexViewStepsOrder.length - 1) {
        this.activeAnnexView = this.annexViewStepsOrder[currentIndex + 1];
      }
    },

    prevStep() {
      const currentIndex = this.annexViewStepsOrder.indexOf(this.activeAnnexView);
      this.activeAnnexView =
        currentIndex > 0
          ? this.annexViewStepsOrder[currentIndex - 1]
          : annexViewSteps.START;
    },

    openSignAnnexModal() {
      this.showSignAnnexModal = true;
      this.emitAgreementTrackerEvent(agreementTrackingEventNames.ANNEX_ACCEPT_PREVIEW);
    },

    // disabled before endDate and 10 years after startDate
    disabledBeforeTodayAnd10YearsAfterStart(date) {
      const endDateMin = DateTime.fromISO(this.agreementFromFirebase.endDate, {
        zone: 'Europe/Warsaw',
      }).startOf('day');

      const endDateMax = DateTime.fromISO(this.agreementFromFirebase.startDate, {
        zone: 'Europe/Warsaw',
      })
        .plus({ years: 10 })
        .startOf('day');

      const inputDate = DateTime.fromJSDate(new Date(date))
        .setZone('Europe/Warsaw')
        .startOf('day');

      return inputDate < endDateMin || inputDate > endDateMax;
    },

    closeAnnexView() {
      this.$emit('closeAnnexView');
    },

    scrollPageUp() {
      window.scrollTo({
        top: 0,
      });
    },

    emitAgreementTrackerEvent(event, customProperties) {
      const annexProps = {
        ...customProperties,
        annexType: trackingProperties.RENEWAL,
      };
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: trackingProcessNames.AGREEMENT,
        agreement: this.agreementFromFirebase,
        props: annexProps,
      });
    },
  },
});
</script>
